<template>
  <section v-if="hasOfficeHours" class="lg:mt-16 pt-24 lg:pt-52 relative">
    <UtilityCrossBackground
      cross-width="619.5"
      cross-height="1215"
      first-cross-class="hidden lg:block absolute right-14 top-0 rotate-[17deg]"
      second-cross-class="hidden"
      :color="parishConfig.colors.secondary.dark"
    />
    <div class="container__fullwidth grid gap-12 lg:grid-cols-2 lg:gap-20 2xl:gap-36 items-center">
      <section v-if="homepage.office?.image">
        <UtilityImage
          :src="homepage.office?.image?.url"
          :alt="homepage.office?.image?.alternativeText || homepage.office?.image?.name"
          class="w-full h-auto 2xl:w-[694px] 2xl:h-[565px] relative"
          image-class="object-cover"
        >
          <UtilityFishImage class="absolute -top-14 -left-8 2xl:-top-16 2xl:-left-24 -rotate-[26deg] w-32 2xl:w-auto text-primary" />
        </UtilityImage>
      </section>
      <section class="space-y-8" :class="{ 'lg:space-y-16': !homepage.office.text, 'lg:space-y-12': homepage.office.text }">
        <Heading v-if="homepage.office?.heading" :text="homepage.office.heading" class="text-center lg:text-left" />
        <CustomTable :table="officeHoursMapper" class="mx-auto max-w-lg lg:max-w-full" />
        <div v-if="homepage.office.text" v-html="homepage.office.text" class="article bg-secondary p-5"></div>
      </section>
    </div>
  </section>
</template>
<script setup lang="ts">
const homepage = useHomepage()
const parishConfig = useParishConfig()
const office = useOffice()
const { ToOfficeHoursTable } = useMappers()

const hasOfficeHours = computed(() => Object.values(office.value).some((val: any) => val.length > 0))
const officeHoursMapper = computed(() => ToOfficeHoursTable(office.value))
</script>
